/* eslint-disable consistent-return */
/*
    Middleware to check if the user is authenticated.
    Otherwise, redirect to the login page.
*/
export default defineNuxtRouteMiddleware(async (from, to) => {
    // Check for expired jwt
    // As soon as we dont have users with 401 anymore
    // we can remove the '/api/auth/session fetch cause we
    // now handle the expiration date of the cookie correclty
    await useFetch('/api/auth/session');

    const jwt = useCookie('jwt');

    const headers = useRequestHeaders();
    const headerJwt = headers?.cookie?.jwt;
    const finalJWT = headerJwt || jwt.value;

    if (!finalJWT) {
        isAuthenticated.value = false;
        const localePath = useLocalePath();
        return navigateTo(localePath('/login'));
    }

    const wincodeCookie = useCookie('wincode');

    const { query, path } = to;
    if (query.code && !path.includes('password-reset')) {
        wincodeCookie.value = query.code;
    }
});
